<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4"
      ><download-excel
        class="btn btn-default"
        :data="listdrug"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="สรุปผลข้อมูลการ X-Ray.xls"
        :header="'สรุปผลข้อมูลการ X-Ray'"
        :stringifyLongNum="true"
      >
        <img src="@/assets/Excel-Logo.png" width="30px" />
        สรุปผลข้อมูลการ X-Ray
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ["listdrug"],
  data() {
    return {
      json_fields: {
        ลำดับ: "count",
        วันที่บันทึก: "createdAt",
        ชื่อจริง: "name",
        ชื่อเล่น: "nickname",
        อายุ: "age",
        เพศ: "gender",
        สัญชาติ: "nationality",
        สัญชาติอื่น: "otherNationality",
        สารเสพติด: "urine",
        ลักษณะการกระทำผิด: "caseType",
        สารเสพติดที่พบ: "drugType",
        สถานี: "station",
        จังหวัด: "province",
        การบำบัด: "treatment",
        // "สารเสพติด": "urine",
        ลักษณะการกระทำผิด: "caseType",
        "ผลตรวจปัสสาวะครั้งที่ 1": "urine1",
        "ผลตรวจปัสสาวะครั้งที่ 2": "urine2",
        "ผลตรวจปัสสาวะครั้งที่ 3": "urine3",
        "ผลตรวจปัสสาวะครั้งที่ 4": "urine4",
        "ผลตรวจปัสสาวะครั้งที่ 5": "urine5",
        "ผลตรวจปัสสาวะครั้งที่ 6": "urine6",
        "ผลตรวจปัสสาวะครั้งที่ 7": "urine7",
        "ผลตรวจปัสสาวะครั้งที่ 8": "urine8",
        "ผลตรวจปัสสาวะครั้งที่ 9": "urine9",
        "ผลตรวจปัสสาวะครั้งที่ 10": "urine10",
        "ผลตรวจปัสสาวะครั้งที่ 11": "urine11",
        "ผลตรวจปัสสาวะครั้งที่ 12": "urine12",
        "ผลตรวจปัสสาวะครั้งที่ 13": "urine13",
        "ผลตรวจปัสสาวะครั้งที่ 14": "urine14",
        "ผลตรวจปัสสาวะครั้งที่ 15": "urine15",
        "ผลตรวจปัสสาวะครั้งที่ 16": "urine16",

        // ภาค: "area",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      listdrug: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.post(
    //     `${process.env.VUE_APP_API}/summaryReport/findAll`,
    //   );
    //   console.log("data", response);
    //   this.list1 = response.data.data;
    // },
  },
};
</script>
